import React, { Component } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import {
  MDBRow, MDBCol, MDBContainer,
  MDBIcon, MDBCard, MDBCardBody, MDBCardFooter, MDBBtn, MDBCardTitle, MDBCardText, MDBRipple
}
from 'mdb-react-ui-kit';
import '../utils'
import eventBus from "../EventBus";

const grammarVocabularyErrors = {
    'ADJ': '形容詞を正しく使いましょう。',
    'ADJ:FORM': '比較級・最上級を正しく使いましょう。２つのものを比較するときには"-er"を使用します。例："nicer"。３つ以上を比較するときには"-est"を使用します。例："nicest"。',
    'ADV': '副詞を正しく使いましょう。',
    'CONJ': '接続詞を正しく使いましょう。',
    'CONTR': '短縮形を正しく使いましょう。例：dont (X), don\'t (○)',
    'DET': '限定詞を正しく使いましょう。',
    'MORPH': '語の形を正しく使いましょう。',
    'NOUN': '名詞を正しく使いましょう。',
    'NOUN:INFL': '不可算名詞を正しく使いましょう。例：baggages(X), baggage(O)',
    'NOUN:NUM': '複数形を正しく使いましょう。',
    'NOUN:POSS': '所有格を正しく使いましょう。例：my school volunteer work(X), my school\'s volunteer work(O)',
    'ORTH': '大文字小文字やスペースを正しく使いましょう。',
    'PART': '熟語を正しく使いましょう。',
    'PREP': '前置詞を正しく使いましょう。例：He agreed with my demands. (X),  He agreed to my demands. (O)',
    'PRON': '代名詞を正しく使いましょう。例：There is relaxing (X), It is relaxing (O)',
    'PUNCT': '句読点を正しく使いましょう。',
    'SPELL': '正しいスペルで書きましょう。',
    'VERB': '動詞を正しく使いましょう。',
    'VERB:FORM': '不定詞、動名詞、分詞を正しく使いましょう。例：I have free time enjoy them (X), I have free time to enjoy them (O).',
    'VERB:INFL': '動詞の形を正しく使いましょう。例：getted (X), got (O)',
    'VERB:SVA': '主語に合わせて動詞を正しく選びましょう。主語が単数形の時は動詞も単数形。主語が複数形の時は動詞も複数形。',
    'VERB:TENSE': '動詞の時制を正しく使いましょう',
    'WO': '正しい語順で書きましょう',
    'OTHER': '正しい言葉を使いましょう',
}

export default class GrammarVocabularyFeedbackCard extends Component {
  constructor(props) {
    super(props);
    this.grammar_err = this.props.feedback.grammar_err?.removeDuplicates()
    this.vocabulary_err = this.props.feedback.vocabulary_err?.removeDuplicates()
  }

  showChat = () => {
    let text = "元の文章は：" + this.props.feedback["org_sent"] + " 薦められた修正は：" + this.props.feedback["cor_sent"]
    eventBus.dispatch("chat", {
      'type': 'sentence',
      'id': this.props.id,
      'text': text
    })
  }

  render() {
    return (
      <MDBCard id={`feedbackcard-${this.props.id}`} key={`feedbackcard-${this.props.id}`} border='dark' className={`feedback text-dark ${this.props.selected ? "selected" : "unselected"} ${this.props.hovered ? "hovered" : ""} mb-3`} feedbackid={this.props.id} onClick={this.props.onClick} onMouseOver={this.props.onMouseOver} onMouseOut={this.props.onMouseOut}>
        <MDBRipple role='button' rippleColor='#6dd6c2'>
          <MDBCardBody className="pb-0">
          <Container className="feedback-card p-0 small">
            {this.grammar_err?.length > 0 && this.props.feedback.is_grammar_error_critical === 1 &&
              <div>
              <MDBIcon fas icon="times-circle" className='me-2 text-danger'/>
              多くの文法間違いがあります。より高い点数を取るためにこれらの文法の間違いを直していきましょう。</div>
            }
            {this.grammar_err?.length > 0 && this.props.feedback.is_grammar_error_critical === 0 &&
              <div><MDBIcon fas icon="info-circle" className='me-2 text-info'/>文法の間違いがあります。作文の点数に影響はありませんが、正しい英語を使うことに心がけしましょう。</div>
            }
            {
              this.grammar_err?.map(error => {
                const error_description = grammarVocabularyErrors[error.substring(2)]
                return (
                  <div key={`feedback-grammar-sentence-text-${this.props.id}`} className='my-3 ms-3'><MDBIcon far icon="check-square" className='me-2'/>{error_description}</div>
                )
              })
            }
            {this.vocabulary_err?.length > 0 && this.props.feedback.is_vocabulary_error_critical === 1 &&
              <div>
              <MDBIcon fas icon="times-circle" className='me-2 text-danger'/>
              意味が正しく伝わらなくなるような多くの語彙の間違いがあります。より高い点数を取るためにこれらの語彙の間違いを直していきましょう。</div>
            }
            {this.vocabulary_err?.length > 0 && this.props.feedback.is_vocabulary_error_critical === 0 &&
              <div><MDBIcon fas icon="info-circle" className='me-2 text-info'/>意味は伝わりますが、語彙の間違いがあります。作文の点数に影響はありませんが、正しい英語を使うことに心がけしましょう。</div>
            }
            {
              this.vocabulary_err?.map(error => {
                const error_description = grammarVocabularyErrors[error.substring(2)]
                return (
                  <div key={`feedback-vocabulary-sentence-text-${this.props.id}`} className='my-3 ms-3'><MDBIcon far icon="check-square" className='me-2'/>{error_description}</div>
                )
              })
            }
            </Container>
          </MDBCardBody>
          <MDBCardFooter className="feedback-footer pt-0">
            <MDBRow>
              <MDBCol className="d-flex align-items-center justify-content-end small text-muted">
                  <span className="me-1">UGUIS先生</span>
                  <MDBBtn floating size="sm" className="green-button" onClick={this.showChat}>
                    <MDBIcon far icon="comment"/>
                  </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCardFooter>
        </MDBRipple>
      </MDBCard>
    )
  }
}
