import React, { useState } from 'react';
import { Modal, Button, Spinner, Alert } from 'react-bootstrap';
import { handleCheckout } from '../Stripe/Checkout';
import './SubscriptionModal.css';

const SubscriptionModal = ({ show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [checkoutError, setCheckoutError] = useState("");

  const handleCheckoutWithState = async (planCode) => {
    setCheckoutError("");
    setLoading(true);
    await handleCheckout(planCode, setCheckoutError);
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>有料プラン申し込み</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          有料会員になると使い放題になります。<br />
          選択した期間ごとのお支払で、定額でサービスをご利用いただけます。
        </p>
        <div className="d-flex flex-column mb-3">
          <Button className="green-button-outline mb-2" onClick={() => handleCheckoutWithState('1m')} disabled={loading}>
            <span className="plan-title">1ヶ月プラン - ¥980（税込）</span><br />
            （1ヶ月あたり ¥980（税込））
          </Button>
          <Button className="green-button-outline mb-2" onClick={() => handleCheckoutWithState('3m')} disabled={loading}>
            <span className="plan-title">3ヶ月プラン - ¥2,850（税込）</span><br />
            （1ヶ月あたり ¥950（税込））
          </Button>
          <Button className="green-button-outline mb-2" onClick={() => handleCheckoutWithState('6m')} disabled={loading}>
            <span className="plan-title">6ヶ月プラン - ¥5,400（税込）</span><br />
            （1ヶ月あたり ¥900（税込））
          </Button>
          <Button className="green-button-outline mb-2" onClick={() => handleCheckoutWithState('12m')} disabled={loading}>
            <span className="plan-title">12ヶ月プラン - ¥9,600（税込）</span><br />
            （1ヶ月あたり ¥800（税込））
          </Button>
        </div>
        {checkoutError && <Alert variant="danger">{checkoutError}</Alert>}
        {loading && <Spinner animation="border" />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={loading}>キャンセル</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionModal;
