import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function CancelCheckoutSession() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/setting');
  }, [navigate]);
  return null;
}

export default CancelCheckoutSession;
