import React, { useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext';
import { getCheckoutSession } from '../api';

function CompleteCheckoutSession() {
  const [searchParams] = useSearchParams();
  const { getUserProfile } = useContext(AuthContext);

  useEffect(() => {
    const fetchCheckoutSession = async () => {
      const sessionId = searchParams.get('session_id');
      if (sessionId) {
        const session = await getCheckoutSession(sessionId);
        if (session && session.status === 'complete') {
          await getUserProfile();
        }
      }
    };

    fetchCheckoutSession();
  }, [searchParams, getUserProfile]);

  return (
    <div>
      <h1>申込完了</h1>
      <p>お申し込みが完了しました。ありがとうございます。</p>
    </div>
  );
}

export default CompleteCheckoutSession;
