import React, { useState, useContext } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import { AuthContext } from '../Auth/AuthContext';
import SubscriptionModal from './SubscriptionModal';
import { ACCOUNT_TYPE_LABELS } from '../constants/AccountTypes';
import { handleBillingPortal } from '../Stripe/BillingPortal';
import './SubscriptionModal.css';

const PricingPlans = () => {
  const { user, profile } = useContext(AuthContext);
  const [checkoutError, setCheckoutError] = useState("");
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  return (
    <Container className="py-5">
      <h1>料金プラン</h1>
      <p class="mt-4">
        有料会員になると使い放題になります。<br />
        選択した期間ごとのお支払で、定額でサービスをご利用いただけます。
      </p>
      <div className="d-flex flex-column mt-3">
        <div className="plan-description mb-2">
          <span className="plan-title">1ヶ月プラン - ¥980（税込）</span><br />
          1ヶ月あたり ¥980（税込）
        </div>
        <div className="plan-description mb-2">
          <span className="plan-title">3ヶ月プラン - ¥2,850（税込）</span><br />
          1ヶ月あたり ¥950（税込）
        </div>
        <div className="plan-description mb-2">
          <span className="plan-title">6ヶ月プラン - ¥5,400（税込）</span><br />
          1ヶ月あたり ¥900（税込）
        </div>
        <div className="plan-description mb-2">
          <span className="plan-title">12ヶ月プラン - ¥9,600（税込）</span><br />
          1ヶ月あたり ¥800（税込）
        </div>
      </div>
      <div className="mt-5">
        {user && (
          <div className="mt-4">
            <div className="mb-3">会員ステータス：{ACCOUNT_TYPE_LABELS[profile?.account_type]}</div>
            {profile?.account_type === 'free' && (
              <Button className="green-button-outline" onClick={() => setShowSubscriptionModal(true)}>有料プラン申込み</Button>
            )}
            {profile?.account_type === 'paid' && (
              <Button className="green-button-outline" onClick={() => handleBillingPortal(setCheckoutError)}>サブスクリプションを管理</Button>
            )}
          </div>
        )}
        {checkoutError && <Alert variant="danger">{checkoutError}</Alert>}
        <SubscriptionModal show={showSubscriptionModal} handleClose={() => setShowSubscriptionModal(false)} />
      </div>
    </Container>
  );
};

export default PricingPlans;
