import * as api from '../api';

export const handleCheckout = async (planCode, setCheckoutError) => {
  try {
    const session = await api.createCheckoutSession(planCode);
    if (session && session.url) {
      window.location.href = session.url;
    } else {
      throw new Error('エラーが発生したためお申込手続きを開始できません。もう一度お試しください。解消しない場合はサポートまでお問い合わせください。');
    }
  } catch (err) {
    setCheckoutError(`${err.name}: ${err.message}`);
  }
};
