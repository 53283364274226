import * as api from '../api';

export const handleBillingPortal = async (setCheckoutError) => {
  setCheckoutError("");
  try {
    const session = await api.createBillingPortalSession(window.location.href);
    if (session && session.url) {
      window.location.href = session.url;
    } else {
      throw new Error('エラーが発生したためカスタマーポータルを開けません。もう一度お試しください。解消しない場合はサポートまでお問い合わせください。');
    }
  } catch (err) {
    setCheckoutError(`${err.name}: ${err.message}`);
  }
};
