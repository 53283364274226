import React from 'react';
import { Container } from 'react-bootstrap';
import classes from './SpecialCommercialLaw.module.css';

const SpecialCommercialLaw = () => {
  return (
    <Container className="py-5">
      <h1>特定商取引法に基づく表記</h1>
      <section className="mt-4">
        <h2>団体名、住所、代表者名</h2>
        <p>株式会社教育測定研究所<br />
          本社所在地：〒108-0075　東京都港区港南1-8-15 Wビル 13F<br />
          代表者　西田　紀子</p>
      </section>
      <section className="mt-4">
        <h2>お問い合わせ先</h2>
        <p>
          <a href="https://support-form.uguis.ai/jiemcs/form/UGUISUAI/formperma/ElWRe_BivWeMLXwYtq9iiAMYfSY0I1aQst5oEWGF9cc/?click=forms" target="_blank">UGUIS.AIサポートセンター</a><br />
          お問い合わせフォーム受付日時：月～金 10:00～17:00（土・日曜日、祝祭日、年末年始は除く）<br />
          メールアドレス：support@uguis.ai<br />
          電話番号：03-6625-7720
        </p>
      </section>
      <section className="mt-4">
        <h2>販売URL</h2>
        <p><a href="https://uguis.ai/">https://uguis.ai/</a></p>
      </section>
      <section className="mt-4">
        <h2>サービスの利用料金</h2>
        <p>有料プランでは、「<a href="/pricing-plans">料金プラン</a>」ページで記載している価格とします。</p>
      </section>
      <section className="mt-4">
        <h2>サービスの利用料金以外の必要料金</h2>
        <p>サービスの利用、お問い合わせなどの際にインターネット接続に関わる通信回線等の諸費用が発生します。</p>
      </section>
      <section className="mt-4">
        <h2>お支払い方法</h2>
        <p>●クレジットカード/電子決済支払い<br />
          以下のクレジットカード/電子決済がご利用いただけます。VISA、MasterCard、JCB、Apple Pay</p>
      </section>
      <section className="mt-4">
        <h2>お支払い期限</h2>
        <p>クレジットカード/電子決済支払い: 各決済会社とお客様との取り決めに基づきます。（引落日はご契約されている決済会社等により異なります。）<br />
          ※一定の無料期間が付与される場合には、お申込み内容確認画面に表示された「課金開始日」となります。（無料期間中に自動更新停止または解約された場合、お支払いは発生しません。）<br />
          ※自動更新は、契約期間満了の翌日となります。（契約期間満了の日までに所定の自動更新停止手続きまたは解約手続きを取らなかった場合、契約が更新され、お支払いが発生します。なお、以降も同様となります。）</p>
      </section>
      <section className="mt-4">
        <h2>サービスの利用開始時期</h2>
        <p>購入手続きが完了次第、利用可能な状態になります。</p>
      </section>
      <section className="mt-4">
        <h2>返品（返金）について</h2>
        <p>サービスの特性上、返品、返金はできません。</p>
      </section>
      <section className="mt-4">
        <h2>キャンセルについて</h2>
        <p>お支払い後のキャンセルはできません。</p>
      </section>
      <section className="mt-4">
        <h2>動作環境</h2>
        <p>
          「<a href="https://support.uguis.ai/portal/ja/kb/articles/9165100" target="_blank">UGUIS動作環境</a>」をご参照ください。
        </p>
      </section>
    </Container>
  );
};

export default SpecialCommercialLaw;
